export const updates = [
  {
    update:
      "🚀 LIDA is now open source on GitHub. Try it out locally on your own data!",
    date: "08/14/2023",
    link: "https://github.com/microsoft/lida",
  },
  {
    update:
      "The LIDA paper has been accepted for publication at ACL 2023 Conference (Demonstration Track)",
    date: "05/08/2023",
    link: "https://aclanthology.org/2023.acl-demo.11/",
  },
];
